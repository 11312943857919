import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  darkBlue,
  InnerWrapper,
  PageTitle,
  Subtitle,
  StyledEmail,
  lightBlue,
  mainWhite,
  lightGrey,
  screen,
} from "../components/common/variables";
import Collapsible from "react-collapsible";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";

const Wrapper = styled.div`
  padding-top: 0;
  background-color: ${mainWhite};
  @media ${screen.medium} {
    padding-top: 70px;
  }

  .large-banner {
    position: relative;
    display: none;
    @media ${screen.small} {
      display: block;
    }

    &__texts-container {
      position: absolute;
      top: 50%;
      z-index: 2;
      right: 50px;
      transform: translateY(-50%);
      @media ${screen.medium} {
        right: 80px;
      }
      @media ${screen.xLarge} {
        right: 140px;
      }
      h1 {
        color: ${mainWhite};
        line-height: 1.1;
        @media ${screen.xSmall} {
          right: 6%;
        }
        @media ${screen.xLarge} {
          right: 170px;
        }
        @media ${screen.xXLarge} {
          right: 10%;
        }
        .thin-white-1,
        .thin-white-2 {
          font-style: italic;
          font-size: 3.3rem;
          font-weight: 300;
          display: none;
          @media ${screen.small} {
            display: inline;
            font-size: 1.9rem;
          }
          @media ${screen.large} {
            font-size: 3.1rem;
          }
          @media ${screen.xLarge} {
            font-size: 3.2rem;
          }
          @media ${screen.xXLarge} {
            font-size: 3.8rem;
          }
        }
        .big {
          color: ${darkBlue};
          font-size: 7.2rem;
          font-weight: 900;
          @media ${screen.xSmall} {
            font-size: 4.4rem;
          }
          @media ${screen.large} {
            font-size: 6.5rem;
          }
          @media ${screen.xLarge} {
            font-size: 7.1rem;
          }
          @media ${screen.xXLarge} {
            font-size: 8.2rem;
          }
        }
        .bold-white {
          font-weight: 900;
        }
        .thin-white-2 {
          margin-left: 210px;
        }
      }

      p {
        color: ${mainWhite};
        display: inline-block;
        margin-top: 30px;
        margin-left: 80px;
        font-size: 1.1rem;
        @media ${screen.small} {
          font-size: 1.1rem;
        }
        @media ${screen.large} {
          font-size: 1.2rem;
        }
        @media ${screen.xLarge} {
          font-size: 1.3rem;
        }
        @media ${screen.xXLarge} {
          font-size: 1.4rem;
        }
      }
      a {
        background: ${mainWhite};
        color: ${lightBlue};
        display: inline-block;
        margin-left: 20px;
      }
    }
  }

  .small-banner {
    display: block;
    @media ${screen.small} {
      display: none;
    }
  }

  .title-mobile {
    display: block;
    margin-bottom: 10px;
    font-weight: 900;
    @media ${screen.small} {
      display: none;
    }
  }

  .contact-btn-mobile {
    display: inline-block;
    width: 100%;
    text-align: center;
    @media ${screen.xSmall} {
      width: auto;
    }
    @media ${screen.small} {
      display: none;
    }
  }

  .inner-wrapper {
    margin-top: 30px;
    @media ${screen.medium} {
      margin-top: 90px;
    }
  }

  .main-description {
    max-width: 674px;
    &__title {
      margin-top: 45px;
      margin-bottom: 5px;
      @media ${screen.small} {
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${darkBlue};
      font-size: 3rem;
      font-weight: 900;
      margin-bottom: 20px;
    }
    p {
      color: ${darkBlue};
      font-size: 1.18rem;
      margin-bottom: 14px;
      @media ${screen.medium} {
        font-size: 1.3rem;
        margin-bottom: 36px;
      }
    }
  }

  .trending-topics {
    margin-top: 30px;
    @media ${screen.medium} {
      margin-top: 90px;
    }
    &__description {
      color: ${darkBlue};
      max-width: 674px;
      font-size: 1rem;
      margin-top: 5px;
      @media ${screen.medium} {
        margin-top: 20px;
        font-size: 1.1rem;
      }
    }

    &__list {
      display: none;
      @media ${screen.small} {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 40px;
      }

      @media ${screen.medium} {
        justify-content: space-between;
      }

      li {
        max-width: 330px;
        margin-bottom: 60px;
        @media ${screen.medium} {
          max-width: 330px;
        }
        @media ${screen.xLarge} {
          max-width: 400px;
        }
      }

      .last-item {
        max-width: 330px;
        margin-bottom: 60px;
        border: 2px solid ${darkBlue};
        height: 255px;
        padding: 25px;
        @media ${screen.small} {
          height: 245px;
          position: relative;
          max-width: 330px;
        }
        @media ${screen.medium} {
          height: 215px;
          left: auto;
          padding: 14px 25px 10px 25px;
        }
        @media ${screen.xLarge} {
          max-width: 400px;
          height: 256px;
          padding: 25px;
        }

        h3 {
          color: ${darkBlue};
          font-size: 1.1rem;
          @media ${screen.medium} {
            font-size: 1.3rem;
          }
          @media ${screen.xLarge} {
            font-size: 1.4rem;
          }
        }

        p {
          color: ${darkBlue};
          font-size: 1rem;
          margin-top: 10px;
          margin-bottom: 15px;
          @media ${screen.xLarge} {
            font-size: 1.1rem;
            margin-top: 15px;
            margin-bottom: 20px;
          }
        }
      }

      &--mobile {
        margin-top: 20px;
        @media ${screen.small} {
          display: none;
        }

        .Collapsible {
          border-top: 2px ${lightGrey} solid;
          position: relative;
          padding: 12px 22px 12px 0;

          h3 {
            color: ${darkBlue};
            max-width: 231px;
            cursor: pointer;

            svg {
              color: ${darkBlue};
              position: absolute;
              right: 10px;
              top: 16px;
              font-size: 1.1rem;
            }
          }

          &__contentInner {
            p {
              color: ${darkBlue};
              font-size: 1rem;
              margin-top: 5px;
            }
          }
        }

        li:last-child .Collapsible {
          border-bottom: 2px ${lightGrey} solid;
        }
      }
    }

    &__img {
      max-width: 400px;
    }

    &__title {
      color: ${darkBlue};
      font-size: 1.6rem;
      font-weight: 900;
      line-height: 1.25;
      margin-top: 30px;
      margin-bottom: 10px;
      @media ${screen.medium} {
        padding: 0 20px;
      }
    }

    &__text {
      color: ${darkBlue};
      font-size: 1rem;
      @media ${screen.medium} {
        padding: 0 20px;
      }
      @media ${screen.xLarge} {
        font-size: 1.1rem;
      }
    }
  }

  .events-spoken {
    margin-top: 35px;
    @media ${screen.small} {
      margin-top: 70px;
    }
    .subtitle {
      text-align: left;
      line-height: 1.3;
      @media ${screen.small} {
        text-align: center;
      }
    }

    &__list {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: 30px;
      @media ${screen.xSmall} {
        justify-content: space-around;
      }
      @media ${screen.medium} {
        justify-content: space-between;
      }
      @media ${screen.large} {
        margin-top: 90px;
      }

      li {
        width: 145px;
        margin-bottom: 25px;
        @media ${screen.xSmall} {
          margin-bottom: 35px;
          width: 270px;
        }
        @media ${screen.xLarge} {
          margin-bottom: 70px;
        }
      }

      &::after {
        content: "";
        max-width: 270px;
        width: 100%;
        background: blue;
      }
    }
  }

  .engaged {
    text-align: left;
    padding-bottom: 90px;
    margin-top: 50px;
    @media ${screen.small} {
      text-align: center;
    }

    &__description {
      font-size: 1.1rem;
      color: ${darkBlue};
      margin: 5px 0 15px 0;
      @media ${screen.xSmall} {
        max-width: 480px;
        font-size: 1.4rem;
        margin: 20px auto 25px auto;
      }
      @media ${screen.large} {
        max-width: 100%;
      }
    }

    &__btn {
      width: 100%;
      text-align: center;
      @media ${screen.xSmall} {
        width: auto;
      }
    }

    a {
      margin: 0 auto;
    }

    &__download-file {
      margin-top: 20px;
      @media ${screen.xSmall} {
        margin-top: 40px;
      }

      a {
        color: ${lightBlue};
        font-size: 1.1rem;
        display: block;
        margin-bottom: 10px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;
const VisionForChange = ({ data }) => {
  const titleTag = data.content.data.title_tag__a_vision_for_change_;
  const metaDescription =
    data.content.data.meta_description__a_vision_for_change_;
  const mainDescription = data.content.data.main_description1.html;
  const trendingTopics = data.content.data.trending_topics;
  const eventList = data.content.data.events_david_has_spoken_at;
  const feeFile = data.general.data.speaking_fees.url;
  const bioFile = data.general.data.biography_file.url;
  const email = data.general.data.email_address;

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Wrapper>
        <header className="large-banner">
          <div className="large-banner__texts-container">
            <h1>
              <span className="thin-white-1">WANT TO MAKE</span>
              <br />
              <span className="big">AN IMPACT</span>
              <br />
              <span className="thin-white-2">
                AT <span className="bold-white">YOUR EVENT?</span>
              </span>
            </h1>
            <p className="large-banner__contact">Get in touch now</p>

            <StyledEmail
              obfuscateChildren={false}
              email={email}
              headers={{
                subject:
                  "I'd like to learn more about how David can make an impact",
              }}
            >
              CONTACT <FaChevronRight />
            </StyledEmail>
          </div>

          <StaticImage
            src="../images/global-adviser-alpha-vision-for-change.jpg"
            alt="Global Adviser Alpha vision for change presenting"
            placeholder="blurred"
          />
        </header>
        <header className="small-banner">
          <StaticImage
            src="../images/global-adviser-alpha-vision-for-change-presenting.jpg"
            alt="Global Adviser Alpha a vision for change"
            placeholder="blurred"
          />
        </header>
        <InnerWrapper className="inner-wrapper">
          <PageTitle className="title-mobile" dark>
            Want to make an
            <br />
            impact at your
            <br /> event?
          </PageTitle>
          <StyledEmail
            className="contact-btn-mobile"
            obfuscateChildren={false}
            email={email}
            headers={{
              subject:
                "I'd like to learn more about how David can make an impact",
            }}
          >
            CONTACT NOW <FaChevronRight />
          </StyledEmail>
          <Subtitle dark className="main-description__title">
            Get David to Speak!
          </Subtitle>
          <main
            className="main-description"
            dangerouslySetInnerHTML={{ __html: mainDescription }}
          />

          <div className="trending-topics">
            <Subtitle dark>Trending topics</Subtitle>
            <p className="trending-topics__description">
              Presentations can be designed and presented on topics specifically
              requested by you. Our most popular presentations are listed below:
            </p>

            <ul className="trending-topics__list">
              {trendingTopics.map((list, i) => {
                return (
                  <li key={i}>
                    <div className="trending-topics__img">
                      <GatsbyImage
                        image={list.featured_image.gatsbyImageData}
                        alt={list.title1}
                      />
                    </div>
                    <h3 className="trending-topics__title">{list.title1}</h3>
                    <p className="trending-topics__text">{list.description}</p>
                  </li>
                );
              })}
              <div className="last-item">
                <h3>These don't sound like you?</h3>
                <p>
                  Get in touch to find out how we can customise our
                  presentations to suit your business needs.
                </p>
                <StyledEmail
                  obfuscateChildren={false}
                  email={email}
                  headers={{
                    subject:
                      "I'd like to learn more about how David can make an impact",
                  }}
                >
                  CONTACT <FaChevronRight />
                </StyledEmail>
              </div>
            </ul>
            <ul className="trending-topics__list--mobile">
              {trendingTopics.map((list, i) => (
                <li key={i}>
                  <Collapsible
                    triggerWhenOpen={
                      <h3>
                        {list.title1}
                        <FaChevronDown />
                      </h3>
                    }
                    trigger={
                      <h3>
                        {list.title1}
                        <FaChevronRight />
                      </h3>
                    }
                    transitionTime={150}
                  >
                    <p>{list.description}</p>
                  </Collapsible>
                </li>
              ))}
            </ul>
          </div>
          <section className="events-spoken">
            <Subtitle className="subtitle" dark>
              Some events David has spoken at
            </Subtitle>
            <ul className="events-spoken__list">
              {eventList.map((client, i) => {
                return (
                  <li key={i}>
                    <GatsbyImage
                      image={client.image_logo.gatsbyImageData}
                      alt="Global Adviser Alpha events spoken at"
                    />
                  </li>
                );
              })}
            </ul>
          </section>

          <div className="engaged">
            <Subtitle dark>Engage with David now!</Subtitle>
            <p className="engaged__description">
              Get in touch to learn more about how David can make an impact, or
              book us in for your next event.
            </p>
            <StyledEmail
              className="engaged__btn"
              obfuscateChildren={false}
              email={email}
              headers={{
                subject:
                  "I'd like to learn more about how David can make an impact",
              }}
            >
              CONTACT <FaChevronRight />
            </StyledEmail>
            <div className="engaged__download-file">
              <a
                href={feeFile}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                Download the fee information
              </a>
              <a
                href={bioFile}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                Download David's full bio
              </a>
            </div>
          </div>
        </InnerWrapper>
      </Wrapper>
    </Layout>
  );
};

export default VisionForChange;

export const data = graphql`
  {
    content: prismicServices {
      data {
        title_tag__a_vision_for_change_
        meta_description__a_vision_for_change_
        main_description1 {
          html
        }
        trending_topics {
          title1
          description
          featured_image {
            alt
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        events_david_has_spoken_at {
          image_logo {
            alt
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }

    general: prismicGeneralDetails {
      data {
        biography_file {
          url
        }
        speaking_fees {
          url
        }
        email_address
      }
    }
  }
`;
